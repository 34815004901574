<template>
  <div class="app-container">
    <el-card>
      <class-record></class-record>
    </el-card>
  </div>
</template>

<script>
import ClassRecord from "./components/ClassRecord.vue";
export default {
  components: {
    ClassRecord,
  },
  data() {
    return {
      activeName: "student",
    };
  },
  methods: {
    handleClick(val) {
      console.log(val);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-tabs__item.is-active {
  font-weight: bold;
  font-size: 15px;
}
/deep/ .el-container {
  width: 100%;
  height: 100% !important;
}
/deep/.el-dialog__title {
  display: flex;
  flex-direction: row;
}
/deep/.el-dialog__body {
  padding: 0px;
  text-align: center;
}
</style>